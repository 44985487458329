<template>
  <ProductsTable />
</template>
<script>
import ProductsTable from './ProductsTable';

export default {
  components: {
    ProductsTable
  },
  props: {}
};
</script>